
import React from 'react';
// import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import Themes from "./themes";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
// import { GlobalProvider } from './context/GlobalContext';

const REACT_VERSION = 17;

//React 17
if (REACT_VERSION == 17) {
  const rootNode = document.getElementById('root');
  ReactDOM.render(<LayoutProvider>
    <UserProvider>
      <ThemeProvider theme={Themes.default}>
        {/* <GlobalProvider> */}
          <CssBaseline />
          <App />
        {/* </GlobalProvider> */}

      </ThemeProvider>
    </UserProvider>
  </LayoutProvider>, rootNode);
}

//React 18
if (REACT_VERSION == 18) {
  const root = ReactDOM.createRoot(document.getElementById('root'));


  root.render(
    <LayoutProvider>
      <UserProvider>

        <ThemeProvider theme={Themes.default}>
          {/* <GlobalProvider> */}
            <CssBaseline />
            <App />
          {/* </GlobalProvider> */}
        </ThemeProvider>



      </UserProvider>
    </LayoutProvider>

  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
