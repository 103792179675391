import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List, Box, Grid, Container } from "@mui/material";
import {
  Dashboard as DashboardIcon,
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  Info as AboutUsIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  AddRoad as AddRoadIcon,
  DirectionsCar as DirectionsCarIcon,
  Place as PlaceIcon,
  LocalShipping as TruckIcon,
  Menu as MenuIcon,
  Fullscreen as FullscreenIcon,
  Class as ClassIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import withRouter from "../Router/withRouter";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { Typography, Tooltip } from "@mui/material";

import TxDOTlogo from "../../images/Transparent Background Color3lineLOGO_TxDOT_RGB_300dpi.png";
import TTIlogo from "../../images/TTI-Color.png";

const structure = [
  { id: 0, type: "divider" },
  { id: 1, label: "Home", description: "Home", link: "/", icon: <HomeIcon /> },
  {
    id: 2,
    label: "Top 100",
    description: "Top 100 Most Congested Road Segments (external link)",
    link: "https://mobility.tamu.edu/texas-most-congested-roadways/",
    icon: <PlaceIcon />,
    fullURL: "https://mobility.tamu.edu/texas-most-congested-roadways/"
  },
  { id: 3, label: "COMPAT", description: "Congestion Management Process Assessment Tool (COMPAT)", link: "/compat", icon: <AddRoadIcon />, fullURL: "https://compat.tti.tamu.edu/" },
  {
    id: 4,
    label: "TCAT",
    description: "Truck Congestion Analysis Tool (TCAT)",
    link: "/tcat",
    icon: <TruckIcon />,
    fullURL: "https://tcat.tti.tamu.edu/"
  },
  { id: 5, label: "Roadway Performance Dashboard", description: "Roadway Performance Dashboard", link: "/roadperfdash", icon: <DashboardIcon />, fullURL: "https://experience.arcgis.com/experience/b9bb72b7783b4750b553888523826337" },
  { id: 6, label: "Mitigation Plan System", description: "Mitigation Plan System", link: "/mitigation-plan", icon: <ClassIcon />, fullURL: "https://mitigation-plan.azurewebsites.net/" },
  { id: 7, type: "divider" },


  // { id: 3, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
  // {
  //   id: 4,
  //   label: "Notifications",
  //   link: "/app/notifications",
  //   icon: <NotificationsIcon />,
  // },
  // {
  //   id: 5,
  //   label: "UI Elements",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Icons", link: "/app/ui/icons" },
  //     { label: "Charts", link: "/app/ui/charts" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },
  // { id: 6, type: "divider" },
  // { id: 7, type: "title", label: "HELP" },
  // { id: 8, label: "Library", link: "https://flatlogic.com/templates", icon: <LibraryIcon /> },
  // { id: 9, label: "Support", link: "https://flatlogic.com/forum", icon: <SupportIcon /> },
  // { id: 10, label: "FAQ", link: "https://flatlogic.com/forum", icon: <FAQIcon /> },
  // { id: 11, type: "divider" },
  // { id: 12, type: "title", label: "PROJECTS" },
  // {
  //   id: 13,
  //   label: "My recent",
  //   link: "",
  //   icon: <Dot size="small" color="warning" />,
  // },
  // {
  //   id: 14,
  //   label: "Starred",
  //   link: "",
  //   icon: <Dot size="small" color="primary" />,
  // },
  // {
  //   id: 15,
  //   label: "Background",
  //   link: "",
  //   icon: <Dot size="small" color="secondary" />
  // }   
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}

    >
      <IconButton
        color="inherit"
        onClick={() => toggleSidebar(layoutDispatch)}
        className={classNames(
          classes.headerMenuButtonSandwich,
          classes.headerMenuButtonCollapse,
        )}
      >
        {isSidebarOpened ? (
          <Tooltip title="Collapse Sidebar">
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Expand Sidebar">
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          </Tooltip>
        )}
      </IconButton>

      <br />
      {isSidebarOpened ? (
        <Typography variant="h3" align="center">
          Texas Congestion
          <br />
          Analysis Tools
          <br />
          (TxCAT)
        </Typography>
      ) : (
        <Typography variant="h6" align="center">
          TxCAT
        </Typography>
      )}

      {/* <div className={classes.toolbar} /> */}
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>

      <List className={classes.sidebarList}>
        {[structure[0], structure[1], structure[2], structure[3], structure[4], structure[5], structure[6], structure[7]].map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
      <div >
        <Tooltip title="Launch Module in New Window">
          <IconButton href={structure.filter((itm) => itm.link === location.pathname)[0].fullURL} target="_blank" >
            <FullscreenIcon className={classes.iconButton} />
            <Typography
              className={classes.linkText}
            >
              Fullscreen
            </Typography>
          </IconButton>
        </Tooltip>
      </div>

      {/* Add Logos */}
      <div >
        <Box
          sx={{
            ...(isSidebarOpened && {
              marginTop: "75%",
            }),
            ...(!isSidebarOpened && {
              marginTop: "300%",
            }),
          }}
        >

          {/* <Tooltip title="Texas Department of Transportation"> */}
          <Box
            component="img"
            sx={{
              ...(isSidebarOpened && {
                height: 64,
                marginLeft: 5
              }),
              ...(!isSidebarOpened && {
                height: 40,
                marginLeft: 0.5
              }),
            }}
            alt="TxDOT Logo."
            src={TxDOTlogo}
            display="flex"
            alignItems="center"
            justifyContent="center"

          />
          {/* </Tooltip> */}

          <br />

          <Box
            component="img"
            sx={{
              ...(isSidebarOpened && {
                height: 25,
                marginTop: 2,
                marginLeft: 2
              }),
              ...(!isSidebarOpened && {
                height: 12,
              }),
            }}
            alt="TTI Logo."
            src={TTIlogo}
          />
        </Box>
      </div>

    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
