import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "@mui/material";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";


export default function MitigationPlanPage() {

    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            style={{ overflow: "none", margin: "0px" }}
        >
            <iframe
                src={"https://mitigation-plan.azurewebsites.net/"}
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
                allow="geolocation"
            ></iframe>
        </Box>

    );
}
