import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";

// context
import { useUserState } from "../context/UserContext";
// #######################################################################
const PrivateRoutes = () => {
  const location = useLocation();
  const { isAuthenticated } = useUserState();

  if (isAuthenticated === undefined) {
    return null; // or loading indicator/spinner/etc
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

// const PublicRoutes = ()=>{
//   const location = useLocation();

//   return <Navigate to={{pathname:"/"}} replace state={{ from: location }} />;
// }

export default function App() {
  // global
  var { isAuthenticated } = useUserState();
  const location = useLocation;

  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );

  // function PrivateRoute({ component, ...rest }) {
  //   return (
  //     <Route
  //       {...rest}

  //       render={props =>
  //         isAuthenticated ? (
  //           React.createElement(component, props)
  //         ) : (
  //           <Navigate
  //             to={{
  //               pathname: "/login",
  //               state: {
  //                 from: props.location,
  //               },
  //             }}
  //           />
  //         )
  //       }
  //     />
  //   );
  // }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Navigate
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
