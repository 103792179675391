import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography, Grid, Item } from "@mui/material";
import content from "./content.json";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ImageCard from "./ImageCard";
import DownloadFiles from "./DownloadFiles";
// import { saveFile } from "./SaveFile";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';

import TxDOTopenDataPortalImage from '../../images/txdot_open_data_portal.PNG';
import TxDOTstatewidePlanningMap from '../../images/txdot_statewide_planning_map.PNG'
import TxDOTtrafficCountsImage from '../../images/txdot_traffic_counts2.PNG'
import TxDOTrefernceMapImage from '../../images/txdot_reference_maps.PNG'
import TxDOTtransportationPlanningProjectsImage from '../../images/txdot_transportation_planning_projects.PNG'

import OverviewAccordion from "./OverviewAccordion";
import OverviewCards from "./OverviewCards";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

const handleDownloadClick = (fileName, type) => {
  // saveFile(fileName, type);
};

/* Constants to be used in Home Page */
const HomePageConstants = {
  PAGE_TITLE: "Texas Congestion Analysis Tools and Resources",
  OVERVIEW: "Overview",
  FEATURES: "Features",
  Reference_FMaterial: "Reference Material",
  USER_GUIDES: "User Guides",
  DWN_USER_GUIDES: "Download User Guides below :",
  CASE_STUDIES: "Mobility Reporting/Case Studies",
  DWN_CASE_STUDIES: "Download Case Studies below :",
  USER_GROUP: "User Groups",
  RESOURCES: "Resources",
  MAINTENANCE:
    "This section's content is under development and yet to be uploaded. Thank you for your patience.",
};

/* Displays tab content */
function CustomTabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ marginLeft: "5px", marginRight: "10px" }}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

/* Displays tab header */
function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function HomePage() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, value) => {
    setValue(value);
  };
  const navigate = useNavigate();

  return (
    <>
      {/* Displays page title */}
      <PageTitle title={HomePageConstants.PAGE_TITLE} />

      {/* Displays image banner */}
      <ImageCard />

      <Box sx={{ width: "100%" }}>
        {/* Displays tab headers */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="home page tabs"
          >
            <Tab label="Overview" {...tabProps(0)} sx={{ align: "left" }} />
            {/* Turning Features tab off
            <Tab label="Features" {...tabProps(1)} sx={{ align: "left" }} /> */}
            <Tab label="Reference Material" {...tabProps(1)} sx={{ align: "left" }} />
            <Tab label="Mobility Reporting/Case Studies" {...tabProps(2)} sx={{ align: "left" }} />
            <Tab label="User Groups" {...tabProps(3)} sx={{ align: "left" }} />
            <Tab label="Resources" {...tabProps(4)} sx={{ align: "left" }} />
          </Tabs>
        </Box>

        {/* Displays overview tab content */}
        <CustomTabPanel value={value} index={0}>
          <br />
          {/* This is a consolidation of multiple tools and resources developed under the direction of TxDOT. Below is an overiew of the tools and resources.
          <br />
          <br /> */}
          {/* <OverviewAccordion /> */}
          <OverviewCards />
        </CustomTabPanel>

        {/* Displays features tab content */}
        {/* Turning Features tab off
        <CustomTabPanel value={value} index={1}>
          <ul sx={{ margin: 0.01 }}>
            {content.features.map((feature) => (
              <li>
                <Typography variant="body1">
                  <strong>{feature.key}</strong>: {feature.value}
                </Typography>
              </li>
            ))}
          </ul>
        </CustomTabPanel> */}

        {/* Displays Reference Material tab content */}
        <CustomTabPanel value={value} index={1}>
          <>
            <Box>
              <br />
              <Typography variant="body1">
                <strong>{HomePageConstants.DWN_USER_GUIDES}</strong>
              </Typography>
              <ul>
                {content.user_guides.map((user_guide) => (
                  <>
                    <li>
                      {/*<DownloadFiles
                        fileName={user_guide.key}
                        displayName={user_guide.value}
                        type="users-guide"
                      /> */}

                      <Typography variant="body1">
                        {user_guide.description}

                        <a href={user_guide.value} target="_blank" style={{ padding: "25px" }}>Download Pdf</a>
                      </Typography>
                    </li>
                  </>
                ))}
              </ul>
            </Box>
          </>
        </CustomTabPanel>

        {/* Displays case study tab content */}
        <CustomTabPanel value={value} index={2}>
          <>
            <Box>
              <br />
              {/* {HomePageConstants.MAINTENANCE} */}
              <Typography variant="body1">
                <strong>Texas Most Congested Roadways</strong>
              </Typography>
              {/* <ul>
                {content.case_studies.map((case_study) => (
                  <li>
                    {case_study.map((c) => (
                      <>
                        <DownloadFiles
                          fileName={c.key}
                          displayName={c.value}
                          type="case-studies"
                        />
                      </>
                    ))}
                    
                  </li>
                ))}
              </ul> */}
              <List sx={{ listStyleType: 'disc' }}>
                <ListItem>
                  <ListItemButton
                    key={"top100-complete-list"}
                    sx={{ height: 40 }}
                    href="https://tti.tamu.edu/documents/umi/most-congested-in-texas-final-2023.pdf"
                    target="_blank">
                    <ListItemText primary="Complete Statewide List" />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton sx={{ height: 40 }}
                    href="https://tti.tamu.edu/documents/TTI-2023-3.pdf"
                    target="_blank">
                    <ListItemText primary="Executive Summary" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </>
        </CustomTabPanel>

        {/* Displays user group tab content */}
        <CustomTabPanel value={value} index={3}>
          <>
            <br />
            {HomePageConstants.MAINTENANCE}
          </>
        </CustomTabPanel>

        {/* Displays resouces tab content */}
        <CustomTabPanel value={value} index={4}>
          <br />
          <>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {/* <Item>xs=8</Item> */}
                <Card sx={{ width: "100%", height: "100%" }}>
                  <CardActionArea
                    href="https://www.txdot.gov/projects/planning.html"
                    target="_blank"
                  >
                    <CardMedia
                      component="img"
                      height="220"
                      image={TxDOTtransportationPlanningProjectsImage}
                      alt="TxDOT Transportation Planning Projects"
                      sx={{ objectFit: "contain" }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        TxDOT Transportation Planning
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Key projects, programs, plans, and initiatives associated with the Transportation Planning Division.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="medium"
                      color="primary"
                      href="https://www.txdot.gov/projects/planning.html"
                      target="_blank"
                    >
                      Open
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={4}>
                {/* <Item>xs=8</Item> */}
                <Card sx={{ width: "100%", height: "100%" }}>
                  <CardActionArea
                    href="https://gis-txdot.opendata.arcgis.com/"
                    target="_blank"
                  >
                    <CardMedia
                      component="img"
                      height="220"
                      image={TxDOTopenDataPortalImage}
                      alt="TxDOT Open Data Portal"
                      sx={{ objectFit: "contain" }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        TxDOT Open Data Portal
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        The portal is the agency’s platform for exploring and downloading GIS datasets. Search for data using
                        keywords or categories to quickly view and display content. The portal allows users to view datasets
                        on a map, filter data using queries, and download data in various formats. Want to learn more?
                        View our datasets using tools like the Statewide Planning Map and Project Tracker.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="medium"
                      color="primary"
                      href="https://gis-txdot.opendata.arcgis.com/"
                      target="_blank"
                    >
                      Open
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ width: "100%", height: "100%" }}>
                  <CardActionArea
                    href="https://www.txdot.gov/apps/statewide_mapping/StatewidePlanningMap.html"
                    target="_blank"
                  >
                    <CardMedia
                      component="img"
                      height="220"
                      image={TxDOTstatewidePlanningMap}
                      alt="TxDOT Statewide Planning Map"
                      sx={{ objectFit: "contain" }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        TxDOT Statewide Planning Map
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Statewide Planning Map.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="medium"
                      color="primary"
                      href="https://www.txdot.gov/apps/statewide_mapping/StatewidePlanningMap.html"
                      target="_blank"
                    >
                      Open
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ width: "100%", height: "100%" }}>
                  <CardActionArea
                    href="https://www.txdot.gov/data-maps/traffic-count-maps/stars.html"
                    target="_blank"
                  >
                    <CardMedia
                      component="img"
                      height="160"
                      image={TxDOTtrafficCountsImage}
                      alt="TxDOT Traffic Analysis and Reporting System (STARS II)"
                      sx={{ objectFit: "contain" }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Statewide Traffic Analysis and Reporting System (STARS II)
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Statewide Traffic Analysis and Reporting System (STARS II) is a data analysis and reporting database with
                        detailed traffic data and statistics.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="medium"
                      color="primary"
                      href="https://www.txdot.gov/data-maps/traffic-count-maps/stars.html"
                      target="_blank"
                    >
                      Open
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ width: "100%", height: "100%" }}>
                  <CardActionArea
                    href="https://www.txdot.gov/data-maps/reference-maps.html"
                    target="_blank"
                  >
                    <CardMedia
                      component="img"
                      height="160"
                      image={TxDOTrefernceMapImage}
                      alt="TxDOT Reference Maps"
                      sx={{ objectFit: "contain" }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        TxDOT Reference Maps
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Reference maps.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="medium"
                      color="primary"
                      href="https://www.txdot.gov/data-maps/reference-maps.html"
                      target="_blank"
                    >
                      Open
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </>
        </CustomTabPanel>
      </Box >
    </>
  );
}
