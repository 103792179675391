import React from "react";
import {
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import withRouter from "../Router/withRouter";
import classnames from "classnames";
import { Box, IconButton, Link } from "@mui/material"
import Icon from '@mdi/react'
import Toolbar from "@mui/material/Toolbar";
//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import HomePage from "../../pages/home/HomePage";
import CompatPage from "../../pages/compat/CompatPage";

// import Typography from "../../pages/typography";
// import Notifications from "../../pages/notifications";
// import Maps from "../../pages/maps";
// import Tables from "../../pages/tables";
// import Icons from "../../pages/icons";
// import Charts from "../../pages/charts";

//contexts
import { useLayoutState } from "../../context/LayoutContext";
import { useLocation } from "react-router-dom";
import TcatPage from "../../pages/tcat/TcatPage";
import RoadPerfDashboardPage from "../../pages/roadperfdashboard/RoadPerfDashboardPage";
import MitigationPlanPage from "../../pages/roadperfdashboard/MitigationPlanPage";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        {/* <Header history={props.history} /> */}
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          {/* <Toolbar></Toolbar> */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/compat" element={<CompatPage />} />
            <Route path="/tcat" element={<TcatPage />} />
            <Route path="/roadperfdash" element={<RoadPerfDashboardPage />} />
            <Route path="/mitigation-plan" element={<MitigationPlanPage />} />
          </Routes>
          {/* <Box
            mt={5}
            width={"100%"}
            // display={"flex"}
            display={"none"}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <div>
              <Link
                color={'primary'}
                href={'https://flatlogic.com/'}
                target={'_blank'}
                className={classes.link}
              >
                Flatlogic
              </Link>
              <Link
                color={'primary'}
                href={'https://flatlogic.com/about'}
                target={'_blank'}
                className={classes.link}
              >
                About Us
              </Link>
              <Link
                color={'primary'}
                href={'https://flatlogic.com/blog'}
                target={'_blank'}
                className={classes.link}
              >
                Blog
              </Link>
            </div>
            <div>
              <Link
                href={'https://www.facebook.com/flatlogic'}
                target={'_blank'}
              >
                <IconButton aria-label="facebook">
                  <Icon
                    path={FacebookIcon}
                    size={1}
                    color="#6E6E6E99"
                  />
                </IconButton>
              </Link>
              <Link
                href={'https://twitter.com/flatlogic'}
                target={'_blank'}
              >
                <IconButton aria-label="twitter">
                  <Icon
                    path={TwitterIcon}
                    size={1}
                    color="#6E6E6E99"
                  />
                </IconButton>
              </Link>
              <Link
                href={'https://github.com/flatlogic'}
                target={'_blank'}
              >
                <IconButton
                  aria-label="github"
                  style={{ marginRight: -12 }}
                >
                  <Icon
                    path={GithubIcon}
                    size={1}
                    color="#6E6E6E99"
                  />
                </IconButton>
              </Link>
            </div>
          </Box> */}
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
